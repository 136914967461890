import React, { useState } from "react";
import { findNode } from "@udecode/plate-common";
import { isEditorReadOnly, PlateRenderElementProps, useEditorRef } from "@udecode/plate-common/react";
import { useSelected } from "slate-react";

import { PlateElementAiTextBlock } from "../../plate-config/Plugins/AiTextBlock/AiTextBlock.plugin";
import AiTextBlockSettings from "./AiTextBlockSettings/AiTextBlockSettings";
import AiTextBlockSummary from "./AiTextBlockSummary/AiTextBlockSummary";
import AiTextBlockPreview from "./AiTextBlockPreview/AiTextBlockPreview";
import { useAppSelector } from "../../store/hooks/redux-hooks";
import { selectedBlockClasses, textColorFromColorClass } from "../../utils/color.util";

const AiTextBlockVoidElement = ({
  attributes,
  children,
  element,
}: PlateRenderElementProps) => {
  const editor = useEditorRef()
  const isReadOnly = isEditorReadOnly(editor)
  const selected = useSelected()
  const colors = useAppSelector(state => state.page_colors.activePalette.colors)
  // CRITICAL NOTE:  Element can depend on general state structure, for example on section font_color, font_size, etc.
  // Due to restrictions of using editorState function, we use redux store to trigger re-render of element (triggerToUpdate).
  const triggerToUpdate = useAppSelector(state => state.page_sections.sections)

  const block = element as PlateElementAiTextBlock

  const nodePath = findNode(editor, { at: [], match: { id: element.id } })![1];
  const activeSection = editor.children[nodePath[0]];
  const sectionChildren = activeSection.children.filter(child => !!child.type)
  const isFirstChild = sectionChildren[0].id === element.id;
  const isLastChild = sectionChildren[sectionChildren.length - 1].id === element.id;

  const activeSectionCardColor = activeSection.card_color as string
  const activeSectionBgColor = activeSection.tint_color as string
  const activeSectionTextColor = activeSection.font_color as string
  const bgColour = activeSectionCardColor ? activeSectionCardColor : activeSectionBgColor
  const dynamicTextColor = textColorFromColorClass(bgColour, colors)
  const textColor = block.primary_color
    ? block.primary_color
    : activeSectionTextColor
      ? activeSectionTextColor : `text-${dynamicTextColor}`
  const generalBlockCheck = !!block.details.prompt && !!block.details.length_min && !!block.details.length_max
    && block.details.length_min <= block.details.length_max && block.details.length_max >= block.details.length_min

  const aiTextReady = block.details.kind === "list"
    ? generalBlockCheck && !!block.details.list_items_min && !!block.details.list_items_max &&
    block.details.list_items_min <= block.details.list_items_max && block.details.list_items_max >= block.details.list_items_min
    : generalBlockCheck

  const [activeView, setActiveView] = useState(aiTextReady ? "preview" : "settings")
  const onActiveViewChange = (view: string) => setActiveView(view)

  if (!aiTextReady && isReadOnly) {
    return null
  }

  return (
    <div
      className={`
      page-block ai-text-block
      ${isFirstChild ? 'first-section-child' : ""} 
      ${isLastChild ? 'last-section-child' : ""}
      ${!isReadOnly && selected ? selectedBlockClasses : ""}
      `}
      data-id={block.id}
      contentEditable={false}
      suppressContentEditableWarning={true}
      data-plate-selectable
      {...attributes}
    >
      {activeView === 'preview'
        ? <AiTextBlockPreview
          element={block}
          onActiveViewChange={onActiveViewChange}
          isEditorReadOnly={isReadOnly}
          textColor={textColor}
          markerColor={dynamicTextColor}
          selected={selected}
        />
        :
        <div className="w-full rounded-xl text-gray-800 shadow-sm bg-white border border-gray-200 p-8 text-left">
          {activeView === 'settings' &&
            <AiTextBlockSettings element={block} onActiveViewChange={onActiveViewChange} aiTextReady={aiTextReady} />
          }
          {activeView === 'summary' &&
            <AiTextBlockSummary element={block} onActiveViewChange={onActiveViewChange} />
          }
        </div>
      }
      {children}
    </div>
  )
}

export default AiTextBlockVoidElement
